import { Controller } from '@hotwired/stimulus'

import { AbsenceCalendarService } from '../services/absence-calendar.service'
import { ConfigLoaderService } from '../services/config-loader.service'
import { Logger } from '../services/logger.service'

export default class extends Controller<HTMLFormElement> {
  static targets = ['calendar']

  protected calendarTarget: HTMLElement

  connect = async () => {
    try {
      const userData = ConfigLoaderService.getConfig('user-data')

      const calendarEl = this.calendarTarget

      const { eventsUrl } = calendarEl.dataset

      const params = {
        eventsUrl,
        calendarEl,
        language: userData.language,
      }

      AbsenceCalendarService.getCalendar(params)
      AbsenceCalendarService.addAbsenceCreatedListener()
      AbsenceCalendarService.render()
    } catch (error) {
      Logger.logException(error)
    }
  }
}
