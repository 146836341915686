import { Calendar } from '@fullcalendar/core'
import { Controller } from '@hotwired/stimulus'

import { urls } from '../config/config'
import { AbsenceCreatedEvent } from '../events/absence-created.event'
import { getUrl } from '../helpers/url.helper'
import { HttpService } from '../services/http.service'
import { LockService } from '../services/lock.service'
import { OffCanvasService } from '../services/off-canvas.service'
import { ToastService } from '../services/toast.service'

export default class extends Controller<HTMLFormElement> {
  static targets = ['form', 'absenceGroupId']
  protected absenceGroupIdTarget: HTMLInputElement
  protected formTarget: HTMLFormElement

  private calendarInstance: Calendar

  save = async (event: MouseEvent) => {
    event.preventDefault()

    const btn = event.currentTarget as HTMLButtonElement
    LockService.lock(btn)

    const formData = new FormData(this.formTarget)
    const groupId = this.absenceGroupIdTarget.value || null

    let url = getUrl(`${urls.absence_api}`)
    if (groupId) {
      url = getUrl(`${urls.absence_api}/${groupId}`)
    }

    try {
      await HttpService.post(url, formData)

      OffCanvasService.hide()

      document.dispatchEvent(new AbsenceCreatedEvent())
    } catch (e) {
      ToastService.showError(e)
    } finally {
      LockService.unlock(btn)
    }
  }

  delete = async (event: MouseEvent) => {
    event.preventDefault()

    const btn = event.currentTarget as HTMLButtonElement

    const groupId = this.absenceGroupIdTarget.value

    if (!groupId) {
      return
    }

    LockService.lock(btn)

    const url = `${urls.absence_api}/${groupId}`

    try {
      await HttpService.delete(getUrl(url))

      OffCanvasService.hide()

      this.calendarInstance.getEventSourceById('absences').refetch()
    } catch (e) {
      ToastService.showError(e)
    } finally {
      LockService.unlock(btn)
    }
  }
}
