import { PaginationInterface } from '../interfaces/pagination.interface'
import { PaginationResultInterface } from '../interfaces/pagination-result.interface'

export class PaginationService {
  /**
   * Returns an object with pagination information.
   *
   * @param {number} current - The current page number.
   * @param {number} max - The maximum page number.
   * @returns {{
   *  current: number,
   *  prev: number | null,
   *  next: number | null,
   *  pageNumbers: (string | number)[]
   * }} - An object with pagination information.
   * @example
   *
   * // Returns:
   * // {
   * //   current: 3,
   * //   prev: 2,
   * //   next: 4,
   * //   pageNumbers: [1, '...', 2, 3, 4, '...', 10]
   * // }
   * getPages(3, 10)
   *
   * // Returns:
   * // {
   * //   current: 1,
   * //   prev: null,
   * //   next: null,
   * //   pageNumbers: [1]
   * // }
   * getPages(1, 1)
   */
  static getPages(
    current: number,
    max: number
  ): PaginationResultInterface | null {
    if (!current || !max) {
      return null
    }

    const isFirstPage = current === 1
    const isLastPage = current === max
    const prev = isFirstPage ? null : current - 1
    const next = isLastPage ? null : current + 1

    const pageNumbers: (string | number)[] = [1]

    if (isFirstPage && isLastPage) {
      return { current, prev, next, pageNumbers }
    }

    const maxVisiblePages = 5
    const start = Math.max(2, current - Math.floor(maxVisiblePages / 2))
    const end = Math.min(max - 1, current + Math.floor(maxVisiblePages / 2))

    if (start > 2) {
      pageNumbers.push('...')
    }

    const pages = Array.from({ length: end - start + 1 }, (_, i) => start + i)
    pageNumbers.push(...pages)

    if (end < max - 1) {
      pageNumbers.push('...')
    }

    if (end < max) {
      pageNumbers.push(max)
    }

    return { current, prev, next, pageNumbers }
  }

  static render = ({
    event,
    total,
    paginationTarget,
    currentPage,
    itemsPerPage,
  }: PaginationInterface) => {
    const pages = PaginationService.getPages(
      currentPage,
      Math.ceil(total / itemsPerPage)
    )

    if (pages) {
      const pagination = pages.pageNumbers.map(page => {
        return this.pageItemHtml(page, currentPage)
      })

      paginationTarget.innerHTML = pagination.join('')

      const buttonList = paginationTarget.querySelectorAll('li button')

      Array.from(buttonList).forEach(button => {
        button.addEventListener('click', event)
      })
    }
  }

  static pageItemHtml = (page: number | string, currentPage: number) => {
    if (page === '...') {
      return `<li class="page-item disabled"><button class="page-link">...</button></li>`
    }

    return `<li class="page-item${page === currentPage ? ' active' : ''}">
            <button class="page-link" data-page="${page}">${page}</button>
            </li>`
  }
}
